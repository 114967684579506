import { type UseModalState } from "@clipboard-health/ui-react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { APP_V2_USER_EVENTS } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";

interface Props {
  modalState: UseModalState;
  onLogin: () => void;
}

export function PhoneNumberExistsDialog(props: Props) {
  const { modalState, onLogin } = props;
  const { modalIsOpen, closeModal } = modalState;

  return (
    <Dialog
      open={modalIsOpen}
      maxWidth="xs"
      onClose={() => {
        closeModal();
        logEvent(APP_V2_USER_EVENTS.CLOSE_PHONE_NUMBER_EXISTS_DIALOG);
      }}
    >
      <DialogTitle>Phone number already exists</DialogTitle>
      <DialogContent dividers>
        <DialogContentText paragraph>
          Hey there, you already have an account with this phone number. Log in to continue.
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          flexDirection: "column",
        }}
      >
        <Button
          fullWidth
          variant="contained"
          aria-label="Log In"
          onClick={() => {
            closeModal();
            onLogin();
            logEvent(APP_V2_USER_EVENTS.SELECT_PHONE_NUMBER_EXISTS_LOGIN);
          }}
        >
          Log In
        </Button>
      </DialogActions>
    </Dialog>
  );
}
