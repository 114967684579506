import { type UseModalState } from "@clipboard-health/ui-react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { APP_V2_USER_EVENTS } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";

interface Props {
  modalState: UseModalState;
  email: string;
}

export function LoginConfirmationDialog(props: Props) {
  const { modalState, email } = props;
  const { modalIsOpen, closeModal } = modalState;

  return (
    <Dialog
      open={modalIsOpen}
      maxWidth="sm"
      onClose={() => {
        closeModal();
      }}
    >
      <DialogTitle>Check Your Email</DialogTitle>

      <DialogContent dividers>
        <DialogContentText paragraph>
          We sent a login link to <b>{email}</b>
        </DialogContentText>
        <DialogContentText>Check your inbox to finish logging in.</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            closeModal();
            logEvent(APP_V2_USER_EVENTS.CLOSE_LOGIN_CONFIRMATION_DIALOG);
          }}
        >
          Back to Log In
        </Button>
      </DialogActions>
    </Dialog>
  );
}
