/* eslint-disable max-lines */
import { LoadingButton, Span, type UseModalState } from "@clipboard-health/ui-react";
import { Text } from "@clipboard-health/ui-react";
import { zodResolver } from "@hookform/resolvers/zod";
import { IonIcon } from "@ionic/react";
import EmailIcon from "@mui/icons-material/Email";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";
import { type OtpRequestChannel, useRequestOtp } from "@src/appV2/Auth/api/useRequestOtp";
import { RequestOtpByChannelButtons } from "@src/appV2/Auth/LoginSignUp/components/RequestOtpByChannelButtons";
import { AuthMode } from "@src/appV2/Auth/LoginSignUp/libs";
import FetchOtpButtonInDevelopmentMode from "@src/appV2/Debug/components/FetchOtpButtonInDevelopmentMode";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import {
  APP_V2_USER_EVENTS,
  extractPhoneNumberDigits,
  ProgressDialog,
  useToast,
} from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { useCountdownTimer } from "@src/appV2/lib/utils/useCountdownTimer";
import { useSupportLinks } from "@src/appV2/support/hooks/useSupportLinks";
import { chevronBack as chevronBackIcon } from "ionicons/icons";
import { useRef } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { useValidateOtp, ValidateOtpError } from "../../api/useValidateOtp";
import { OtpDeliveryHelpMessage } from "../../components/OtpDelivery";

const VALID_OTP_LENGTH = 6;
const INVALID_OTP_MESSAGE = `Please enter a valid ${VALID_OTP_LENGTH}-digit code`;
const verifyOtpFormSchema = z.object({
  otp: z
    .string()
    .min(VALID_OTP_LENGTH, { message: INVALID_OTP_MESSAGE })
    .max(VALID_OTP_LENGTH, { message: INVALID_OTP_MESSAGE }),
});

interface Props {
  modalState: UseModalState;
  phoneNumber: string;
  onSuccess: (params: {
    firebaseToken: string;
    agentDeletionRequestCancelled: boolean;
  }) => Promise<void>;
  authMode: AuthMode;
  switchToEmailAuth?: () => void;
}

export function VerifyOtpDialog(props: Props) {
  const { modalState, phoneNumber, onSuccess, authMode, switchToEmailAuth } = props;
  const { modalIsOpen, closeModal } = modalState;

  const { mutateAsync: validateOtp, isLoading: isVerifyingOtp } = useValidateOtp();

  const {
    mutateAsync: requestOtp,
    isLoading: isRequestingOtp,
    isSuccess: isRequestOtpSuccess,
  } = useRequestOtp();

  const { showErrorToast } = useToast();
  const { howToContactSupportLink } = useSupportLinks();

  const formMethods = useForm({
    defaultValues: {
      otp: "",
    },
    resolver: zodResolver(verifyOtpFormSchema),
  });

  const {
    formState: { errors, isSubmitting },
    reset,
    register,
    handleSubmit,
    setError,
    setValue,
  } = formMethods;

  const formRef = useRef<HTMLFormElement>();
  const otpFieldProps = register("otp");
  const resetFormAndCloseModal = () => {
    reset();
    closeModal();
  };

  const isTwilioVerificationApiEnabled = useCbhFlag(CbhFeatureFlag.TWILIO_VERIFICATION_API, {
    defaultValue: false,
  });

  const { countdownIsLoading, startTimer, secondsLeft } = useCountdownTimer({
    durationInSeconds: 30,
  });

  async function onClick(channel: OtpRequestChannel = "sms") {
    if (countdownIsLoading) {
      return;
    }

    try {
      await requestOtp({
        phone: extractPhoneNumberDigits(phoneNumber),
        isSignup: authMode === AuthMode.SIGN_UP,
        channel,
      });

      logEvent(APP_V2_USER_EVENTS.RESEND_OTP, {
        phoneNumber,
        isSignup: authMode === AuthMode.SIGN_UP,
        channel,
      });
    } catch {
      showErrorToast("Something went wrong while requesting an OTP code. Please try again.");
    } finally {
      startTimer();
    }
  }

  return (
    <>
      <Dialog fullScreen open={modalIsOpen} maxWidth="xs">
        <Box
          ref={formRef}
          component="form"
          sx={{ display: "flex", flexDirection: "column", height: "100%" }}
          onSubmit={handleSubmit(async (formData) => {
            logEvent(APP_V2_USER_EVENTS.SUBMIT_AUTH_OTP_FORM);

            const { data } = await validateOtp({
              phone: extractPhoneNumberDigits(phoneNumber),
              otp: formData.otp,
              isSignup: authMode === AuthMode.SIGN_UP,
            });

            if (data.message === "otpIsValid") {
              await onSuccess({
                firebaseToken: data.data.firebaseToken,
                agentDeletionRequestCancelled: data.data.agentDeletionRequestCancelled,
              });
              return true;
            }

            if (
              data.error === ValidateOtpError.INVALID_OTP ||
              data.error === ValidateOtpError.OTP_EXPIRED ||
              data.error === ValidateOtpError.INVALID_USER_FOR_LOGIN ||
              data.error === ValidateOtpError.INVALID_USER_FOR_SIGNUP
            ) {
              const formError = { type: "server", message: data.message };
              setError("otp", formError);
              logEvent(APP_V2_USER_EVENTS.SUBMIT_AUTH_OTP_FORM_ERROR);
              return false;
            }

            return false;
          })}
        >
          <DialogTitle
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Button
              aria-label="Return to login"
              onClick={() => {
                resetFormAndCloseModal();
                logEvent(APP_V2_USER_EVENTS.CLOSE_VERIFY_OTP_DIALOG);
              }}
            >
              <IonIcon icon={chevronBackIcon} size="large" />
            </Button>
            <Span marginX="auto" textAlign="left">
              Enter your verification code
            </Span>
          </DialogTitle>

          <DialogContent dividers>
            <DialogContentText paragraph>
              We&apos;ve just sent a verification code to{" "}
              <Span sx={{ whiteSpace: "nowrap" }}>
                <b>{phoneNumber}</b>
              </Span>
              .
            </DialogContentText>
            <TextField
              fullWidth
              label="Verification Code"
              autoComplete="one-time-code"
              type="number"
              inputProps={{
                inputMode: "numeric",
              }}
              {...otpFieldProps}
              error={Boolean(errors.otp)}
              helperText={errors.otp?.message}
              onChange={async (event) => {
                const { value } = event.target;
                await otpFieldProps.onChange(event);
                if (value.length === VALID_OTP_LENGTH) {
                  formRef.current?.dispatchEvent(
                    new Event("submit", { cancelable: true, bubbles: true })
                  );
                }
              }}
            />

            <FetchOtpButtonInDevelopmentMode
              phoneNumber={phoneNumber}
              onSuccess={(otp) => {
                setValue("otp", otp);
                formRef.current?.dispatchEvent(
                  new Event("submit", { cancelable: true, bubbles: true })
                );
              }}
            />

            <Box sx={{ mt: isTwilioVerificationApiEnabled ? 2 : 0 }}>
              {isTwilioVerificationApiEnabled ? (
                <>
                  <Text variant="subtitle1" sx={{ mb: 2 }}>
                    {countdownIsLoading
                      ? `Resend in ${secondsLeft}s`
                      : "Didn't get a code? Resend it using one of the possible options 👇"}
                  </Text>
                  <RequestOtpByChannelButtons
                    disabled={countdownIsLoading}
                    isRequestingOtp={isRequestingOtp}
                    requestOtp={async (channel: OtpRequestChannel) => {
                      await onClick(channel);
                    }}
                  />
                </>
              ) : (
                <Button
                  type="button"
                  variant="text"
                  size="large"
                  sx={{ my: 1, px: 1, gap: 1 }}
                  disabled={countdownIsLoading || isRequestingOtp}
                  onClick={async () => {
                    await onClick();
                  }}
                >
                  {countdownIsLoading
                    ? `Resend in ${secondsLeft}s`
                    : "Didn't get a code? Resend it now."}
                </Button>
              )}
              {isRequestOtpSuccess && (
                <OtpDeliveryHelpMessage authMode={authMode} supportLink={howToContactSupportLink} />
              )}
            </Box>
            {authMode === AuthMode.LOGIN && switchToEmailAuth && isTwilioVerificationApiEnabled ? (
              <Box sx={{ mt: 2 }}>
                <Text variant="subtitle1" sx={{ mt: 2, mb: 2 }}>
                  Alternatively, try a different login method
                </Text>
                <Stack gap={2}>
                  <LoadingButton
                    size="large"
                    variant="outlined"
                    type="button"
                    isLoading={false}
                    sx={{ flex: 1, gap: 1 }}
                    onClick={async () => {
                      resetFormAndCloseModal();
                      switchToEmailAuth();
                    }}
                  >
                    <EmailIcon /> Send Email Link
                  </LoadingButton>
                </Stack>
              </Box>
            ) : null}
          </DialogContent>
          <DialogActions>
            <Button
              fullWidth
              type="submit"
              variant="contained"
              size="large"
              disabled={isSubmitting}
            >
              Continue
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      <ProgressDialog modalIsOpen={isVerifyingOtp} messageContent="Verifying..." />
    </>
  );
}
/* eslint-enable max-lines */
