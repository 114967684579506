import { LoadingButton, type UseModalState } from "@clipboard-health/ui-react";
import { DialogContentText } from "@mui/material";
import { CommonDialog } from "@src/appV2/lib/Dialogs/Common/Dialog";

interface Props {
  modalState: UseModalState;
  onConfirm: () => Promise<void>;
  isLoading: boolean;
}

export function ReactivationRequiredDialog(props: Props) {
  const { modalState, onConfirm, isLoading } = props;

  return (
    <CommonDialog
      modalState={modalState}
      title="Account Verification Required"
      actions={
        <LoadingButton
          fullWidth
          variant="contained"
          isLoading={isLoading}
          aria-label="OK"
          onClick={async () => {
            await onConfirm();
            modalState.closeModal();
          }}
        >
          Start Verification
        </LoadingButton>
      }
      onClose={() => {
        modalState.closeModal();
      }}
    >
      <DialogContentText paragraph>
        Your account requires verification to continue. Please verify your phone number and email
        address to continue.
      </DialogContentText>
    </CommonDialog>
  );
}
