import { type UseModalState } from "@clipboard-health/ui-react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
} from "@mui/material";
import { DeploymentEnvironmentName, environmentConfig } from "@src/appV2/environment";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { useBreakpoint } from "@src/appV2/lib/mui";
import { SupportArticles, SupportContext } from "@src/appV2/support/constants";
import { useSupportLinks } from "@src/appV2/support/hooks/useSupportLinks";
import { useHistory } from "react-router-dom";

import { AuthMode } from "../libs";

export enum SignUpInsteadDialogAuthMethod {
  PHONE = "phone number",
  EMAIL = "email",
}

interface Props {
  modalState: UseModalState;
  authMethod: SignUpInsteadDialogAuthMethod;
}

export function SignUpInsteadDialog(props: Props) {
  const { modalState, authMethod } = props;
  const { modalIsOpen, closeModal } = modalState;
  const history = useHistory();
  const screenIsMobile = useBreakpoint("md");
  const { howToContactSupportLink } = useSupportLinks();

  const hcfAppDynamicLink =
    environmentConfig.REACT_APP_ENVIRONMENT_NAME === DeploymentEnvironmentName.PRODUCTION
      ? "https://clipboardfacility.page.link/open-hcf-app"
      : "https://stageclipboardfacility.page.link/open-hcf-app";

  return (
    <Dialog
      open={modalIsOpen}
      maxWidth="sm"
      fullScreen={screenIsMobile}
      onClose={() => {
        closeModal();
        logEvent(APP_V2_USER_EVENTS.CLOSE_SIGN_UP_INSTEAD_DIALOG);
      }}
    >
      <DialogTitle>We can&apos;t find that {authMethod}</DialogTitle>
      <DialogContent dividers>
        <DialogContentText paragraph>
          {authMethod === SignUpInsteadDialogAuthMethod.PHONE &&
            `Sign up with this ${authMethod}, or try logging in with a different one.`}

          {authMethod === SignUpInsteadDialogAuthMethod.EMAIL &&
            "Try logging in with a different email."}
        </DialogContentText>

        <DialogContentText paragraph>
          <b>
            Trying to log in as a facility user? You’re on the app for professionals - click here to
            download the{" "}
            <Link href={hcfAppDynamicLink} target="_blank">
              Clipboard Facility app
            </Link>
            .
          </b>
        </DialogContentText>

        {howToContactSupportLink && (
          <DialogContentText paragraph>
            If you think this is an error, then please contact our{" "}
            <Link
              href={howToContactSupportLink}
              target="_blank"
              onClick={() => {
                logEvent(APP_V2_USER_EVENTS.OPENED_SUPPORT_ARTICLE, {
                  articleLink: howToContactSupportLink,
                  articleName: SupportArticles.HOW_TO_CONTACT_SUPPORT,
                  supportContext:
                    authMethod === SignUpInsteadDialogAuthMethod.PHONE
                      ? SupportContext.PHONE_LOGIN_WITH_FACILITY_MOBILE_APP_INSTEAD
                      : SupportContext.EMAIL_LOGIN_WITH_FACILITY_MOBILE_APP_INSTEAD,
                });
              }}
            >
              24/7 Support Team
            </Link>
            .
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions
        sx={{
          flexDirection: "column",
        }}
      >
        {authMethod === SignUpInsteadDialogAuthMethod.PHONE && (
          <Button
            fullWidth
            variant="contained"
            onClick={() => {
              history.push(AuthMode.SIGN_UP);
              closeModal();
              logEvent(APP_V2_USER_EVENTS.SELECT_SIGN_UP_INSTEAD);
            }}
          >
            Sign Up
          </Button>
        )}

        <Button
          fullWidth
          variant="outlined"
          onClick={() => {
            closeModal();
            logEvent(APP_V2_USER_EVENTS.CLOSE_SIGN_UP_INSTEAD_DIALOG);
          }}
        >
          Try a different {authMethod}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
